//TODO adjust the theme instead...

$greyBackground: #f8f8f8;
$foodfactsGreen: #2ab673;

:root {
  --amazonOrange: #{$foodfactsGreen};
  --button-click: #{darken($foodfactsGreen, 30%)};
  --ion-color-primary-tint: #{darken($foodfactsGreen, 30%)};
}

body {
    background-color: white;
}

.content {
    width: 90%;
    margin: auto;
}

.ant-layout {
    background: white !important;
}

.sidebar-menu {
  height: 100%;
  background-color: white !important;
  margin-top: 10vh !important;

  * {
    font-size: 20px;
    font-weight: 300;
  }
}

.ant-table {
    td {
        font-size: 20px;
        font-weight: 300;
    }
}

.ant-layout-sider-light {
    background-color: white !important;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
    background-color: $greyBackground !important;
}

.ant-layout-footer {
    background-color: $greyBackground !important;
}

.header {
  height: 100%;

  .ant-typography h2,
  h2.ant-typography {
    margin: 0 !important;
  }
}

.main-wrapper {
    background-color: white;
}

.header-wrapper {
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0 2px 8px #f0f1f2;

    z-index: 100;
}

.header-avatar {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.mark-item {
    border-radius: 3px;
    animation: highlight 1000ms ease-out;
}

@keyframes highlight {
    0% {
        background-color: $foodfactsGreen;
    }
    100% {
        background-color: inherit;
    }
}

.disableOnSmallScreen {
    display: block;
}

.enableOnSmallScreen {
    display: none;
}

@media (max-width: 768px) {
    .disableOnSmallScreen {
        display: none;
    }
    .enableOnSmallScreen {
        display: block;
    }
}

.hoverable:hover {
    background-color: yellow;
}
